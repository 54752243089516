import I18n from 'i18n-js'
import { setLocale } from 'yup'

I18n.defaultLocale = 'lv'
I18n.locale = document.documentElement.getAttribute('lang')
I18n.fallbacks = true
I18n.availableLocales = document.documentElement.dataset['langs'].split(',')

export default I18n
export const t = I18n.t.bind(I18n)
export const l = I18n.l.bind(I18n)

setLocale({
  mixed: {
    required: t('errors.messages.blank')
  }
})
