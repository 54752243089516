import { DependencyList, EffectCallback, useEffect, useRef } from 'react'

import { fromUnixTime } from 'date-fns'
import format from 'date-fns/format'
import formatISO from 'date-fns/formatISO'
import parse from 'date-fns/parse'
import parseISO from 'date-fns/parseISO'
import I18n from 'i18n'
import type { List } from 'immutable'
import { isArray, toNumber } from 'lodash'
import has from 'lodash/has'
import isEqual from 'lodash/isEqual'
import padStart from 'lodash/padStart'
import mitt, { Emitter } from 'mitt'
import {
  order_management_order_path,
  order_management_quote_order_path,
  production_planner_manufacturing_order_path
} from 'routes'

export const onContentLoaded = (fn) => {
  if (document.readyState != 'loading') {
    fn()
  } else {
    document.addEventListener('DOMContentLoaded', fn)
  }
}

export function getMetaValue(name: string) {
  const metaTag = document.querySelector(`meta[name='${name}']`)
  return metaTag ? metaTag.getAttribute('content') : null
}

export function featureEnabled(name: string): boolean {
  return window['features'].indexOf(name) > -1
}

export function isBlank(v) {
  return v === undefined || v === null || v === ''
}

export function isNumber(input) {
  return !isBlank(input) && !isNaN(Number(input))
}

export const NBSP = '\u00A0'

export const DEFAULT_TIME_FORMAT = 'HH:mm'
export const DEFAULT_DATE_FORMAT = 'dd.MM.yyyy'

interface FormatDateParameters {
  value: string
  dateFormat?: string
  inputDateFormat?: string
  fallback?: string
}

export const formatDateFromISO = ({
  value,
  dateFormat = DEFAULT_DATE_FORMAT,
  fallback = ''
}: FormatDateParameters): string => {
  return reformatDate({ value, inputDateFormat: null, dateFormat, fallback })
}

export const reformatDate = ({ value, inputDateFormat, dateFormat, fallback = '' }: FormatDateParameters): string => {
  try {
    const parsed = inputDateFormat ? parse(value, inputDateFormat, new Date()) : parseISO(value)
    return dateFormat ? format(parsed, dateFormat) : formatISO(parsed)
  } catch (_) {
    return fallback
  }
}

export function inputToNumber(value: string, defaultValue = 0): number {
  const parsed = parseFloat(value.replace(',', '.'))
  return parsed || defaultValue
}

export function removeLineBreaks(string: string): string {
  return string && string.replace(/(\r\n|\n|\r)/gm, '')
}

export const formatSecondsToHoursAndMinutes = (seconds: number): string => {
  let hours = Math.floor(seconds / 3600)
  let minutes = Math.ceil((seconds % 3600) / 60)

  while (minutes >= 60) {
    minutes -= 60
    hours += 1
  }

  return `${hours}:${padStart(minutes.toString(), 2, '0')}`
}

type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[]
  ? ElementType
  : never

type IsImmutableAlready<T> = T extends Map<any, any> ? T : T extends List<any> ? T : never
type IsObject<T> = T extends Date ? never : T extends object ? T : never
type ImmutableElement<T> = T extends IsImmutableAlready<T> ? T : T extends IsObject<T> ? ImmutableJsObject<T> : T
type ImmutableValue<T> = T extends unknown[] ? List<ImmutableElement<ArrayElement<T>>> : ImmutableElement<T>

/**
 * Type for correct type checking of Immutable Objects
 */
export interface ImmutableJsObject<T extends object> {
  toObject: () => T
  toJS: () => T
  update: <K extends keyof T>(
    key: K,
    updater: (value: ImmutableValue<T[K]>) => ImmutableValue<T[K]>
  ) => ImmutableJsObject<T>
  get: <K extends keyof T>(key: K, fallback?: ImmutableValue<T[K]>) => ImmutableValue<T[K]>
  set: <K extends keyof T>(key: K, value: ImmutableValue<T[K]>) => ImmutableJsObject<T>
  deleteIn: (keyPath: any[]) => ImmutableJsObject<T>
  getIn: (keyPath: (string | number)[]) => any
  setIn: (keyPath: any[], value: any) => ImmutableJsObject<T>
  hashCode: () => number
}

export const isImmutableJsObject = <T extends object>(
  object: ImmutableJsObject<T> | T
): object is ImmutableJsObject<T> => {
  return object && (object as ImmutableJsObject<T>).toJS != null
}

export const isImmutable = <T>(object: List<any> | Map<any, any> | T): object is List<any> | Map<any, any> => {
  return object && (object as List<any>).toJS != null
}

export const round = (maybeNumber: any, precision = 2): number => {
  const number = Number(maybeNumber)

  if (!isFinite(number)) {
    return 0
  }

  // http://www.jacklmoore.com/notes/rounding-in-javascript/
  const rounded = Number(`${Math.round(Number(`${number}e${precision}`))}e-${precision}`)

  if (isFinite(rounded)) {
    return rounded
  } else {
    return 0
  }
}

/**
 * Transforms user input(`hh:mm:ss` or `hh:mm`) into seconds
 * @example
 * normalizeTimeInput('5')              // => 5
 * normalizeTimeInput('00:00:05')       // => 5
 * normalizeTimeInput('2:2')            // => 122
 * normalizeTimeInput('2:2', 'minutes') // => 7320
 * normalizeTimeInput('60')             // => 60
 * normalizeTimeInput('60', 'minutes')  // => 3600
 * normalizeTimeInput('86401')          // => 86401
 */
export const normalizeTimeInput = (value: string, precision?: 'minutes' | 'seconds'): number => {
  const timeParts = value.split(':').reverse()
  const numericTimeParts = timeParts.map((part) => parseInt(part) || 0)

  if (precision === 'minutes') {
    // eslint-disable-next-line prefer-const
    let [minutes, hours] = numericTimeParts
    hours ??= 0
    minutes ??= 0

    return hours * 3600 + minutes * 60
  } else {
    // eslint-disable-next-line prefer-const
    let [seconds, minutes, hours] = numericTimeParts
    hours ??= 0
    minutes ??= 0
    seconds ??= 0

    return hours * 3600 + minutes * 60 + seconds
  }
}

/**
 * @param seconds Amount of seconds
 * @param precision Optional precision - `minutes` or `seconds`. Defaults to `seconds`
 * @returns {string} Returns formatted string of `"HH:mm:ss"`(or `"HH:mm"` if `precision` set to `'minutes'`)
 * @example
 * formattedTimeSec('5')                // => '00:00:05'
 * formattedTimeSec(122)                // => '00:02:02'
 * formattedTimeSec(122, 'minutes')     // => '00:02'
 * formattedTimeSec('86401')            // => '24:00:01'
 * formattedTimeSec('86401', 'minutes') // => '24:00'
 */
export const formattedTimeSec = (seconds: number | string, precision?: 'minutes' | 'seconds'): string => {
  seconds = typeof seconds === 'number' ? seconds : parseInt(seconds)
  const hours = Math.floor(seconds / 3600) // If hours are > 24, moment.format rolls them over to next day and displays 0
  const timeFormat = precision === 'minutes' ? ':mm' : ':mm:ss'

  return `${hours}` + format(fromUnixTime(seconds), timeFormat)
}

export const outerHeight = (el?: HTMLElement): number => {
  if (el == null) {
    return 0
  }

  let height = el.offsetHeight
  const style = getComputedStyle(el)

  height += parseInt(style.marginTop) + parseInt(style.marginBottom)
  return height
}

export const formatWithLocale = (date: number | Date, formatString: string) =>
  format(date, formatString, { locale: I18n.dateFnsLocale })

export const formatNumber = (number: number): string =>
  number.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })

export const truncateNumber = (number: number, decimalPlaces: number) => round(number, decimalPlaces).toString()

export const useDeepCompareEffect = <TDeps extends DependencyList>(effect: EffectCallback, deps: TDeps) => {
  const depsRef = useRef<TDeps | undefined>()

  if (!depsRef || !isEqual(depsRef.current, deps)) {
    depsRef.current = deps
  }

  useEffect(effect, depsRef.current)
}
export const useMitt = <T extends Record<string, unknown>>() => {
  if (!has(window, 'mitt.signature')) {
    const instance = mitt<T>()
    window['mitt'] = { signature: true, instance }
    return instance as Emitter<T>
  }
  return window['mitt'].instance as Emitter<T>
}

export function truncateNumericStringArray(value: string[], decimalPlaces: number) {
  if (isArray(value)) {
    return value.map((v) => {
      if (!v) {
        return null
      }
      if (v.endsWith('.')) {
        return v
      }
      const number = toNumber(v)
      if (isNaN(number)) {
        return '0'
      }
      return truncateNumber(number, decimalPlaces).toString()
    })
  }
}

export function truncateNumericString(value: string, decimalPlaces: number) {
  const number = toNumber(value)
  if (isNaN(number)) {
    return '0'
  }
  return truncateNumber(number, decimalPlaces).toString()
}

export const orderPath = (orderId: number, orderKind: 'sale' | 'quote' | 'manufacturing') => {
  switch (orderKind) {
    case 'quote':
      return order_management_quote_order_path(orderId)
    case 'sale':
      return order_management_order_path(orderId)
    case 'manufacturing':
      return production_planner_manufacturing_order_path(orderId)
  }
}

export const parseDate = (dateString: string) => parse(dateString, DEFAULT_DATE_FORMAT, new Date())

export const displayCurrency = (value, currency = '') => {
  return `${(value || 0).toFixed(2)} ${currency}`.trim()
}

interface Railable {
  [key: string]: any
  _destroy: boolean
}

export const visibleRailishChildren = (list: Railable[]): Railable[] => {
  return list && list.filter((entry) => visibleRailishChild(entry))
}

export const visibleRailishChild = (entry: Railable): boolean => entry._destroy == false
